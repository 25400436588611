import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery, styled, useTheme } from '@mui/material';
import clsx from 'clsx';
import throttle from 'lodash/throttle';
import { isBrowser } from '@app/common/helpers/gatsby';
import { TOP_NAV_HEIGHT } from '../../components/v1/website/SwTopNav';
import { grey7 } from './bedrock/SwColors';
import SwFlexBox from './bedrock/layout/SwFlexBox';

export type SwContentLayoutProps = {
    children: React.ReactNode;
    leftSide?: React.ReactNode;
    rightSide?: React.ReactNode;
};

export type SwContentFullWidthProps = {
    children: React.ReactNode;
    id: string;
};

const StickyContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: var(--_main-gap);
    position: sticky;
    top: calc(var(--_main-gap) + ${TOP_NAV_HEIGHT});

    & *:is(.sw-paper, .sw-collapsible-section) {
        backdrop-filter: blur(0) opacity(1);
        background-color: ${grey7};
        transition: background-color 0.15s ease-in-out;
    }

    &.-faded *:is(.sw-paper, .sw-collapsible-section) {
        background-color: rgba(249, 249, 249, 0.8);
        backdrop-filter: blur(2px) opacity(1);
    }
`;

const Main = styled('div')<{ withPaddingOnMobile?: boolean }>`
    padding-inline: calc(var(--_sidebar-inline-size) + var(--_main-gap));
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        padding-inline: 0px;

        &.sw-title-card {
            padding-inline: 20px;
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-inline: ${({ withPaddingOnMobile }) => (withPaddingOnMobile ? 20 : 0)}px;

        &.sw-title-card {
            padding-inline: 20px;
        }
    }
`;

const StyledFullWidth = styled('div')`
    inline-size: 100%;
    block-size: 100%;
    max-block-size: 585px;
    padding-inline: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;

    & > * {
        position: relative;
        block-size: unset;
        inset-inline: unset;
        top: 0;
        bottom: 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        --_overflow-offset: 16px;
        block-size: 530px;

        & > * {
            position: absolute;
            inset-block: unset;
            block-size: calc(530px + (4 * var(--_overflow-offset)));
            top: calc(var(--_overflow-offset) * -2);
            bottom: calc(var(--_overflow-offset) * -2);
            inset-inline: 0;
        }
    }
`;

const LeftSide = styled('div')`
    position: absolute;
    inset-block: 0;
    left: 0;
    height: 100%;
    inline-size: var(--_sidebar-inline-size);
    z-index: 1;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        position: relative;
        inset-block: unset;
        left: unset;
        height: auto;
        inline-size: 100%;
    }
`;

const RightSide = styled('div')`
    position: absolute;
    inset-block: 0;
    right: 0;
    z-index: 1;
    height: 100%;
    inline-size: var(--_sidebar-inline-size);
    z-index: 1;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        position: relative;
        inset-block: unset;
        right: unset;
        height: auto;
        inline-size: 100%;
    }
`;

const RelativeContainer = styled('div')`
    --_sidebar-inline-size: 210px;
    --_main-gap: 16px;

    position: relative;
    inline-size: 100%;
    block-size: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--_main-gap);
`;

export const FullWidth: React.FC<SwContentFullWidthProps> = ({ children, id, ...props }) => {
    return (
        <StyledFullWidth className={'sw-content-layout__full-width'} id={id} {...props}>
            {children}
        </StyledFullWidth>
    );
};

const ROOT_MARGIN = 16;

export const SwContentLayout: React.FC<SwContentLayoutProps> & {
    Main: React.FC<JSX.IntrinsicElements['div'] & { withPaddingOnMobile?: boolean }>;
    FullWidth: React.FC<SwContentFullWidthProps>;
} = (props) => {
    const { children, leftSide, rightSide } = props;
    const [isOverlapping, setIsOverlapping] = useState<boolean>(false);
    const isTabletOrLess = useMediaQuery(useTheme().breakpoints.down('md'));
    const fullWidthElements = useRef([]);

    const containerRef = useRef(null);
    const leftSideRef = useRef(null);

    // Callback to see if the sidebar is overlapping a full width element
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callBack = useCallback(
        throttle(() => {
            if (!leftSideRef.current) {
                return;
            }

            const leftSideBarTop = leftSideRef.current.offsetTop;
            const leftSideBarBottom =
                leftSideRef.current.offsetTop + leftSideRef.current.getBoundingClientRect().height;

            const isOverlapping = fullWidthElements.current.some(
                ([top, bottom]) =>
                    (leftSideBarTop >= top && leftSideBarTop <= bottom) ||
                    (leftSideBarBottom >= top && leftSideBarBottom <= bottom)
            );
            setIsOverlapping(isOverlapping);
        }, 100),
        []
    );

    useEffect(() => {
        // Get all the full width elements top and bottom
        if (isBrowser() && !isTabletOrLess && !fullWidthElements.current.length) {
            fullWidthElements.current = Array.from(document.querySelectorAll('.sw-content-layout__full-width')).map(
                (e: any) => [e.offsetTop - ROOT_MARGIN, e.offsetTop + e.getBoundingClientRect().height + ROOT_MARGIN]
            );
        }
    }, [isTabletOrLess]);

    useEffect(() => {
        if (!isTabletOrLess) {
            document.addEventListener('scroll', callBack);
        } else {
            document.removeEventListener('scroll', callBack);
            setIsOverlapping(false);
        }

        return () => document.removeEventListener('scroll', callBack);
    }, [callBack, isTabletOrLess]);

    return (
        <RelativeContainer ref={containerRef}>
            <LeftSide>
                <SwFlexBox flexDirection={'column'} height={'100%'}>
                    <StickyContainer
                        className={clsx({
                            '-faded': isOverlapping
                        })}
                        ref={leftSideRef}
                    >
                        {leftSide}
                    </StickyContainer>
                </SwFlexBox>
            </LeftSide>
            <SwFlexBox alignItems={'center'} flexDirection={'column'} gap={16}>
                {children}
            </SwFlexBox>
            <RightSide>
                {Boolean(rightSide) && (
                    <SwFlexBox flexDirection={'column'} gap={16} height={'100%'}>
                        <StickyContainer
                            className={clsx({
                                '-faded': isOverlapping
                            })}
                        >
                            {rightSide}
                        </StickyContainer>
                    </SwFlexBox>
                )}
            </RightSide>
        </RelativeContainer>
    );
};

SwContentLayout.FullWidth = FullWidth;
SwContentLayout.Main = Main;
