import { RichTextProps } from '@graphcms/rich-text-react-renderer';
import React from 'react';
import { styled } from '@mui/material';
import { SwCmsTheme } from './SwCmsRenderer';
import { SwContentLayout } from './SwContentLayout';
import { SwRichContent } from './SwRichContent';
import { grey5, white } from './bedrock/SwColors';

export type SwContentCardProps = {
    children?: React.ReactNode;
    content: {
        json: RichTextProps['content'];
        references: RichTextProps['references'];
    };
    cmsTheme?: SwCmsTheme;
};

const Paper = styled('div')<{ $backgroundColor?: string }>`
    width: 100%;
    background-color: ${({ $backgroundColor }) => $backgroundColor ?? white};
    border: 1px solid ${grey5};
    border-radius: ${({ theme }) => theme.spacing(1)};
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        padding: 20px;
    }
`;

export const SwContentCard: React.FC<SwContentCardProps> = (props) => (
    <SwContentLayout.Main className={'sw-content-card'}>
        <Paper>
            <SwRichContent
                className={props.cmsTheme?.name}
                content={props.content.json}
                references={props.content.references}
            />
        </Paper>
    </SwContentLayout.Main>
);
