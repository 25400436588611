import React from 'react';
import { styled } from '@mui/material';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwContentCard } from '@lib/components/SwContentCard';
import { SwContentLayout } from '@lib/components/SwContentLayout';
import { SwTableOfContents } from '@lib/components/SwTableOfContents';
import { grey3, grey6 } from '@lib/components/bedrock/SwColors';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { AspectRatios } from '@lib/enums/apectRatios';
import FlashBanner from '@app/common/components/FlashBanner';
import Seo from '@app/common/components/Seo';
import SwLayout from '../components/v1/SwLayout';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';

const CoverImage = styled('div')`
    aspect-ratio: ${AspectRatios.widescreen};
    overflow: hidden;
    will-change: transform;
    z-index: 0;
    position: relative;
    width: 100%;
    max-block-size: 570px;
`;

const SidebarContainer = styled('div')`
    display: flex;
    gap: 16px;
    flex-direction: column;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-inline: 20px;
    }
`;

const TitleCard = styled(SwPaper)`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 16px;
    inline-size: fit-content;
`;

const LogoContainer = styled(SwPaper)`
    aspect-ratio: ${AspectRatios.square};
    height: 64px;
`;

const TitleCardContainer = styled(SwContainer)`
    position: absolute;
    z-index: 1;
    top: 32px;
`;

const LandingTemplate = ({ data }) => {
    const landing = data.graphCmsLandingPage;

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.blog.key}
                customHeader={{
                    endLogoText: landing.navigation?.endLogoText,
                    goBackLink: landing.navigation?.goBackLink,
                    signUpLink: landing.navigation?.signupLink
                }}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwLayout background={grey6}>
                <CoverImage>
                    <TitleCardContainer>
                        <TitleCard padding={4}>
                            <LogoContainer backgroundColor={'transparent'} padding={1}>
                                {Boolean(landing.company) && (
                                    <GatsbyImage
                                        alt={landing.company.logo.alt}
                                        image={landing.company.logo.gatsbyImageData}
                                        imgStyle={{ objectFit: 'contain' }}
                                        objectFit={'contain'}
                                        style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                                    />
                                )}
                            </LogoContainer>

                            <SwTypography component={'h1'} variant={'h3'}>
                                {landing.title}
                            </SwTypography>
                            <SwTypography color={grey3} variant={'body2'}>
                                {landing.abstract}
                            </SwTypography>
                        </TitleCard>
                    </TitleCardContainer>
                    <GatsbyImage
                        alt={landing.coverImage.alt}
                        image={landing.coverImage.gatsbyImageData}
                        objectFit={'cover'}
                        style={{ width: '100%', height: '100%' }}
                    />
                </CoverImage>
                <SwContainer paddingBlock={'32px'} paddingInline={{ xs: '0px' }}>
                    <SwContentLayout
                        leftSide={
                            <SidebarContainer>
                                <SwTableOfContents json={landing.content.json} />
                            </SidebarContainer>
                        }
                        rightSide={<></>}
                    >
                        <SwContentCard content={landing.content} />
                    </SwContentLayout>
                </SwContainer>
                <SwWebsiteFooter newsletterForm={data.newsletterForm} />
            </SwLayout>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        graphCmsLandingPage: { seo }
    }
}) => (
    <Seo
        description={seo.description}
        image={seo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const getLandingQuery = graphql`
    query getLandingQuery($id: String!) {
        latestArticles: allGraphCmsArticle(
            filter: { locale: { eq: en }, industry: { id: { eq: null } }, seo: { noIndex: { ne: true } } }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: en } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        graphCmsLandingPage(id: { eq: $id }) {
            id
            abstract
            title
            slug
            locale
            company {
                ...Essentials_GraphCMS_Company
            }
            navigation {
                id
                endLogoText
                goBackLink
                signupLink
            }
            content {
                json
                references {
                    ...Essentials_GraphCMS_Asset
                    ...Essentials_GraphCMS_Quote
                    ...Essentials_GraphCMS_CustomerQuote
                    ...Essentials_GraphCMS_CallOut
                }
            }
            seo {
                ...Essentials_GraphCMS_Seo
            }
            coverImage {
                alt
                gatsbyImageData(aspectRatio: 1.778, layout: CONSTRAINED, height: 900, placeholder: BLURRED)
            }
        }
    }
`;

export default LandingTemplate;
